import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="non-turbo-form"
export default class extends Controller {
  submit(event) {
    event.preventDefault();

    const buttonElement = event.submitter;
    const buttonContent = buttonElement.querySelector(
      ".Polaris-Button__Content",
    );

    buttonElement.disabled = true;
    buttonElement.classList.add(
      "Polaris-Button--disabled",
      "Polaris-Button--loading",
    );
    buttonContent.insertAdjacentHTML("afterbegin", this.spinnerHTML);

    this.element.submit();
  }

  get spinnerHTML() {
    return `\n<span class="Polaris-Button__Spinner">\n<span class="Polaris-Spinner Polaris-Spinner--sizeSmall">\n<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">\n<path d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z"></path>\n</svg>\n</span>\n</span>\n`;
  }
}
